import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';

import { hubGqlClient } from '~/libs/gql';
import { graphql } from '~/types/__generated/gql';
import { GetLocationByIdQuery } from '~/types/__generated/gql/graphql';
import { GetOperationalLocationsResults, getOperationalLocationsKey } from '../useGetOperationalLocations';

const query = /* GraphQL */ `
	query GetLocationById($id: ObjectID!) {
		getLocationById(id: $id) {
			_id
			created_at
			updated_at
			venue
			name
			type
			payment_types
			status
			hash
			no_vat
			no_service_charge
			is_pay_later_allowed
			is_pay_now_allowed
			locations_groups_tags
			hidden_items_configs {
				items
				item_types
				categories
			}

			_current_orders {
				_id
				created_at
				updated_at
				index
				items {
					_id
					category
					item
					title
					original_price
					original_price_addons {
						vat {
							percentage
							amount
						}
						service_charge {
							percentage
							amount
						}
					}
					unit_price
					listed_price
					quantity
					extra_quantity
					serving_quantity
					comments
					cancelled_reason
					recipe {
						raw_material
						unit_of_measurement
						consumed_unit_of_measurement
						consumed_quantity
					}
					options {
						_id
						option
						uid
						title
						quantity
						original_price
						original_price_addons {
							vat {
								percentage
								amount
							}
							service_charge {
								percentage
								amount
							}
						}
						listed_price
						recipe {
							raw_material
							unit_of_measurement
							consumed_unit_of_measurement
							consumed_quantity
						}
					}
					type
					delivered_count
					status
					prep_time
					printer_tag
					subtotal
					subtotal_addons {
						offer {
							metadata
							amount
						}
						discount {
							is_divided
							type
							value
							amount
						}
						vat {
							is_included
							percentage
							amount
						}
						service_charge {
							is_included
							percentage
							amount
						}
						adjustment {
							amount
						}
					}
					net_amount
					gross_amount
					cancelled_amount
					is_paid
				}
				cancelled_items {
					_id
					category
					item
					title
					original_price
					original_price_addons {
						vat {
							percentage
							amount
						}
						service_charge {
							percentage
							amount
						}
					}
					unit_price
					listed_price
					quantity
					extra_quantity
					serving_quantity
					comments
					cancelled_reason
					recipe {
						raw_material
						unit_of_measurement
						consumed_unit_of_measurement
						consumed_quantity
					}
					options {
						_id
						option
						uid
						title
						quantity
						original_price
						original_price_addons {
							vat {
								percentage
								amount
							}
							service_charge {
								percentage
								amount
							}
						}
						listed_price
						recipe {
							raw_material
							unit_of_measurement
							consumed_unit_of_measurement
							consumed_quantity
						}
					}
					type
					delivered_count
					status
					prep_time
					printer_tag
					subtotal
					subtotal_addons {
						offer {
							metadata
							amount
						}
						discount {
							is_divided
							type
							value
							amount
						}
						vat {
							is_included
							percentage
							amount
						}
						service_charge {
							is_included
							percentage
							amount
						}
						adjustment {
							amount
						}
					}
					net_amount
					gross_amount
					cancelled_amount
					is_paid
				}
				prepared_at
				processed_at
				paid_at
				cancelled_at
				prepared_by
				processed_by
				paid_by
				cancelled_by
				user
				customer
				venue
				status
				is_paid
				payway_ref
				payway_verification_data
				staff
				original_orders
				cancelled_reason
				source
				device
				prep_time
				currencies_configs
				location
				current_location
				max_prepare_time
				items_count
				items_count_by_types
				prepped_count
				subtotal
				offer_amount
				discount_amount
				net_amount
				vat_amount
				service_charge_amount
				adjustment_amount
				grand_total
				gross_amount
				cancelled_amount
				receipt
				is_needing_prep_time_confirmation
				note
				is_cancelled
				is_bill_printed
				is_needing_payment_confirmation
				ticket_linebreaks
			}
		}
	}
`;

export type GetLocationByIdResults = GetLocationByIdQuery['getLocationById'];

export const getLocationByIdQueryKey = (id: string) => ['getLocationById', id];

export const useGetLocationById = (id?: string, enabled?) => {
	const queryClient = useQueryClient();

	return useQuery({
		enabled: enabled ?? !!id,
		queryKey: getLocationByIdQueryKey(id!),
		queryFn: () => hubGqlClient.request(graphql(query), { id: id! }).then((res) => res.getLocationById),
		onSuccess: (data) => {
			queryClient.setQueryData<GetOperationalLocationsResults>(getOperationalLocationsKey(), (prevData) =>
				prevData ? prevData.map((l) => (l._id === data._id ? data : l)) : [data]
			);
		},
	});
};

export const useGetLocationByIdCache = (id?: string) => {
	return useGetLocationById(id, false);
};

export const useGetCurrentLocation = (enabled?: boolean) => {
	const {
		query: { id },
	} = useRouter();

	return useGetLocationById(id === 'new' ? undefined : String(id || ''), enabled);
};

export const useGetCurrentLocationCache = () => {
	return useGetCurrentLocation(false);
};

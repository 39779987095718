import localforage from 'localforage';

import { LOCAL_STORAGE_KEYS } from '~/configs';

const browserStorage = {
	token: {
		get: () => localforage.getItem<string>(LOCAL_STORAGE_KEYS.TOKEN),
		set: (str: any) => localforage.setItem(LOCAL_STORAGE_KEYS.TOKEN, str),
		remove: () => localforage.removeItem(LOCAL_STORAGE_KEYS.TOKEN),
	},
	denominator: {
		get: () => localforage.getItem<string>(LOCAL_STORAGE_KEYS.DENOMINATOR),
		set: (str: any) => localforage.setItem(LOCAL_STORAGE_KEYS.DENOMINATOR, str),
		remove: () => localforage.removeItem(LOCAL_STORAGE_KEYS.DENOMINATOR),
	},
	venueId: {
		get: () => localforage.getItem<string>(LOCAL_STORAGE_KEYS.VENUE_ID),
		set: (str: any) => localforage.setItem(LOCAL_STORAGE_KEYS.VENUE_ID, str),
		remove: () => localforage.removeItem(LOCAL_STORAGE_KEYS.VENUE_ID),
	},
	preferredLocation: {
		get: () => localforage.getItem<string>(LOCAL_STORAGE_KEYS.PREFERRED_LOCATION),
		set: (str: any) => localforage.setItem(LOCAL_STORAGE_KEYS.PREFERRED_LOCATION, str),
		remove: () => localforage.removeItem(LOCAL_STORAGE_KEYS.PREFERRED_LOCATION),
	},
	preferredLocationView: {
		get: () => localforage.getItem<string>(LOCAL_STORAGE_KEYS.PREFERRED_LOCATION_VIEW),
		set: (str: any) => localforage.setItem(LOCAL_STORAGE_KEYS.PREFERRED_LOCATION_VIEW, str),
		remove: () => localforage.removeItem(LOCAL_STORAGE_KEYS.PREFERRED_LOCATION_VIEW),
	},
	preferredCalendarView: {
		get: () => localforage.getItem<string>(LOCAL_STORAGE_KEYS.PREFERRED_CALENDAR_VIEW),
		set: (str: any) => localforage.setItem(LOCAL_STORAGE_KEYS.PREFERRED_CALENDAR_VIEW, str),
		remove: () => localforage.removeItem(LOCAL_STORAGE_KEYS.PREFERRED_CALENDAR_VIEW),
	},
	tillStates: {
		get: () => localforage.getItem<string>(LOCAL_STORAGE_KEYS.TILL_STATES),
		set: (str: any) => localforage.setItem(LOCAL_STORAGE_KEYS.TILL_STATES, str),
		remove: () => localforage.removeItem(LOCAL_STORAGE_KEYS.TILL_STATES),
	},
};

export { browserStorage };

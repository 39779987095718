import '@fontsource/roboto/100.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';
import { AppProps } from 'next/app';
import Router from 'next/router';
import { SnackbarProvider } from 'notistack';
import { useEffect } from 'react';
import 'react-simple-keyboard/build/css/index.css';
import { useBoolean, useEffectOnce, useEventListener } from 'usehooks-ts';

import { ErrorBoundary, SplashScreen } from '@served/ui-comps';

import { AppEnvChecker } from '~/components/AppEnvChecker';
import { AppUpdateChecker } from '~/components/AppUpdateChecker';
import { ErrorAlert } from '~/components/ErrorAlert';
import { FeatureRestrictionChecker } from '~/components/FeatureRestrictionChecker';
import { InfoAlert } from '~/components/InfoAlert';
import { LogoutModal } from '~/components/LogoutModal';
import { OfflineDialog } from '~/components/OfflineDialog';
import { OperationalNotification } from '~/components/OperationalNotification';
import { PushNotification } from '~/components/PushNotification';
import { useRouterEvent } from '~/libs/google-analytics';
import { useNprogress } from '~/libs/nprogrss';
import { AppProvider } from '~/providers/AppProvider';
import { QueryProvider } from '~/providers/QueryProvider';
import { useIsReady, useResetApp } from '~/store';

export default function MyApp({ Component, pageProps, router: appRouter }: AppProps) {
	const { value: isMounted, setTrue: setMounted } = useBoolean(false);
	const resetApp = useResetApp();

	useEffectOnce(() => {
		setMounted();
	});

	useEventListener('unload', () => {
		resetApp();
		window.$queryClient?.clear?.();
	});

	useEffect(() => {
		const onReceiveServiceWorkerMessage = (event: MessageEvent) => {
			if (!event.data.action) return;

			switch (event.data.action) {
				case 'redirect-from-notificationclick':
					Router.replace(event.data.url);
					break;
			}
		};

		if (navigator.serviceWorker)
			navigator.serviceWorker.addEventListener('message', onReceiveServiceWorkerMessage);

		return () => {
			if (navigator.serviceWorker)
				navigator.serviceWorker.removeEventListener('message', onReceiveServiceWorkerMessage);
		};
	}, []);

	useNprogress(appRouter);
	useRouterEvent();

	const isReady = useIsReady();

	if (!isMounted) return null;

	return (
		<ErrorBoundary
			appName="STAFF APP"
			id="1239419474851926046"
			token="MtDfsszAvQR3NoKauliuvWRd0pW3Ck5pDioUFlIeYrvHS1pE-B-VIAwzLSbVncZv24iS"
		>
			<QueryProvider>
				<AppProvider>
					<SnackbarProvider
						anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
						preventDuplicate
						maxSnack={100}
					>
						<AppEnvChecker />
						<OfflineDialog />
						<LogoutModal />
						<InfoAlert />
						<ErrorAlert />
						<AppUpdateChecker />
						<FeatureRestrictionChecker />
						<PushNotification />
						<OperationalNotification />

						{isReady ? <Component {...pageProps} /> : <SplashScreen />}
					</SnackbarProvider>
				</AppProvider>
			</QueryProvider>
		</ErrorBoundary>
	);
}

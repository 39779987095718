import { deleteToken } from 'firebase/messaging';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { CALENDAR_VIEW, PREFERRED_LOCATION_VIEW } from '~/configs/constants';
import { messaging } from '~/libs/firebase';
import { browserStorage } from '~/libs/localforage';
import { useGetActiveMenuForPos } from '~/queries/useGetActiveMenuForPos';
import { useGetCurrentVenue } from '~/queries/useGetCurrentVenue';
import { useGetOffers } from '~/queries/useGetOffers';
import { useGetTags } from '~/queries/useGetTags';
import { useLoginSilent } from '~/queries/useLogin';
import { getState, setState, useSetIsReady, useSetVenueId, useVenueId } from '~/store';
import { State } from '~/types/store';

const useInitApp = () => {
	const router = useRouter();

	useRestoreNonSensitiveLocalStorageData();

	const venueId = useVenueId();
	const setIsReady = useSetIsReady();
	const setVenueId = useSetVenueId();
	const loginSilent = useLoginSilent();
	const { refetch: refetchMenu } = useGetActiveMenuForPos(false);
	const { refetch: refetchVenue } = useGetCurrentVenue(false);
	const { refetch: refetchOffers } = useGetOffers(false);
	const { refetch: refetchTags } = useGetTags(false);

	// INIT `venueId` state
	useEffect(() => {
		async function start() {
			// use this instead of query, to get id on the first render
			const url = new URL(window.location.href);
			const queryId = url.searchParams.get('id') || url.pathname.replaceAll(/\/|login/g, '');

			// accessing /login/[id] page on first start
			if (url.pathname.startsWith('/login') && !!queryId) {
				const cachedVenueId = await browserStorage.venueId.get();

				// same venue
				if (cachedVenueId === queryId) {
					setVenueId(queryId);
					return;
				}

				// accessing different venue
				getState().resetApp();
				window.$queryClient?.clear?.();
				await deleteToken(messaging).catch(console.log);
				await browserStorage.token.remove();
				await browserStorage.venueId.set(queryId);
				setVenueId(queryId);
				return;
			}

			// NOT accessing /login page on first start
			const cachedVenueId = await browserStorage.venueId.get();
			if (!cachedVenueId) {
				await router.replace('/login');
				setIsReady(true);
				return;
			}

			setVenueId(cachedVenueId);
		}

		if (!venueId) start();
	}, [venueId, router.pathname, router.query.id]);

	// init app process only after `venueId` is set
	useEffect(() => {
		async function start() {
			const cachedToken = await browserStorage.token.get();

			// no login token found
			if (!cachedToken) {
				const redir =
					router.pathname === '/' || router.asPath.startsWith('/login')
						? router.query.redir
							? encodeURIComponent(router.query.redir.toString())
							: null
						: encodeURIComponent(router.asPath);

				await router.replace([`/login/${venueId}`, redir && `redir=${redir}`].filter(Boolean).join('?'));
				setIsReady(true);
				return;
			}

			// found login token
			try {
				await loginSilent.mutateAsync();
				await Promise.all([refetchMenu(), refetchVenue(), refetchOffers(), refetchTags()]);

				const destinationRoute = router.pathname.startsWith('/ops/till')
					? '/ops/till'
					: router.pathname === '/' || router.asPath.startsWith('/login')
					? '/ops/orders'
					: router.asPath;
				await router.replace(destinationRoute);
				setIsReady(true);
			} catch (e) {
				await browserStorage.token.remove();
				const redir =
					router.pathname === '/' || router.asPath.startsWith('/login')
						? router.query.redir
							? encodeURIComponent(router.query.redir.toString())
							: null
						: encodeURIComponent(router.asPath);

				await router.replace([`/login/${venueId}`, redir && `redir=${redir}`].filter(Boolean).join('?'));
				setIsReady(true);
			}
		}

		if (venueId) start();
	}, [venueId]);
};

export { useInitApp };

const useRestoreNonSensitiveLocalStorageData = () => {
	useEffect(() => {
		async function start() {
			const states: Partial<State> = {};

			const [denominator, preferredLocation, preferredLocationView, calendarView] = await Promise.all([
				browserStorage.denominator.get(),
				browserStorage.preferredLocation.get(),
				browserStorage.preferredLocationView.get(),
				browserStorage.preferredCalendarView.get(),
			]);
			if (denominator !== null) states.denominator = Number(denominator);
			if (preferredLocation !== null) states.preferredLocation = preferredLocation;
			if (preferredLocationView !== null)
				states.preferredLocationView = preferredLocationView as PREFERRED_LOCATION_VIEW;
			if (calendarView !== null) states.preferredCalendarView = calendarView as CALENDAR_VIEW;

			setState(states);
		}

		start();
	}, []);
};
